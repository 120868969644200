.intro--container {
  width: 100%;
  padding: 1.5rem;
  background-color: #fff;
}

.intro--content {
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 20px;
  width: 100%;
  background-color: var(--blue);
  padding: 3rem 0;
}

.intro--gif-and-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: auto;
}

.intro--gif-wrapper {
  padding: 1rem 0 1rem 0;
  width: 50%;
}

.intro--gif {
  transform: translateX(-25px);
  width: 90%;
}

.intro--text-and-btn {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.intro--text-and-btn p {
  margin-bottom: 2rem;
}

.intro--text-and-btn p:nth-child(2) {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.2;
}

.intro--btn {
  color: #fff;
  font-weight: 600;
  background-color: var(--red);
  padding: 0.4rem;
  padding-bottom: 0.5rem;
  border-radius: 50px;
  align-self: flex-start;
}

.intro--btn span {
  padding: 1rem;
}

@media all and (max-width: 1024px) {
  .intro--gif-and-text-wrapper {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .intro--gif {
    width: 100%;
    transform: translateX(0);
  }

  .intro--text-and-btn {
    width: 70%;
  }
}

@media all and (max-width: 768px) {
  .intro--text-and-btn {
    width: 90%;
  }

  .intro--gif-wrapper {
    width: 80%;
  }
}