.projectcard--wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.projectcard--hover-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: linear-gradient(bottom, #00ff00 50%, transparent 50%);
  background: -moz-linear-gradient(bottom, #00ff00 50%, transparent 50%);
  background: -webkit-linear-gradient(bottom, #00ff00 50%, transparent 50%); */
  opacity: 0%;
  transform: translateY(10%);
  /* transition-property: opacity, transform; */
  transition-property: transform;
  transition-duration: .5s;
  /* pointer-events: none;  */
}

.projectcard--hover-area:hover {
  height: 100%;
  transform: translate(0);
  opacity: 100%;
}

.projectcard--hover-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  padding: 0 1.5rem;
  background-color: var(--red);;
  opacity: 90%;
}

.projectcard--hover-card h2{
  line-height: 1.2;
  padding-bottom: 0.2rem;
}

.projectcard--card-divider {
  border: 0;
  border-top: 3px solid #000;
}

.projectcard--tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  row-gap: 0;
  column-gap: 0.5rem;
  width: 100%;
}

.projectcard--tags-wrapper span{
  line-height: 1.4;
}

.projectcard--img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*  */

.projectcard--placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: #f0f0f0; /* Cor neutra */
  border-radius: 8px;
}

.placeholder-img {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  padding: 10px;
}

@keyframes loading {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}
