.instafeed--container {
  padding: 2rem 0 6rem 0;
}

.instafeed--grid {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 70%;
}

.instafeed--icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  color: var(--red);
}

.instafeed--grid-title {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
}

.instafeed--grid-media {
  padding: 0.5rem;
  background-color: rgb(237, 237, 237);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.instafeed--media-wrapper {
  width: 33%;
  padding: 0.5rem;
}

.instafeed--media {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media all and (max-width: 1024px) {
  .instafeed--grid {
    width: 100%;
  }
}

@media all and (max-width: 768px) {
  .instafeed--media-wrapper {
    width: 50%;
  }
}

@media all and (min-width: 1500px) {
  .instafeed--grid {
    width: 90%;
  }
}