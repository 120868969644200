.lang-btn {
  position: absolute;
  z-index: 2;
  top: 5%;
  right: 8%;
  background-color: transparent;
  color: #fff;
  font-size: medium;
}

.lang-btn--line {
  position: absolute;
  border-bottom: 3px solid var(--red);
  height: 10px;
  width: 40%;
}

.pt-selected {
  bottom: -5px;
  left: 0;
}

.en-selected {
  bottom: -5px;
  right: 0;
}