:root {
  --red: #e54a38;
  --blue: #001944;
}

iframe {
  background-color: rgb(231, 231, 231);
  box-shadow: inset 0 0 0 5px #fff;
}

* {
  line-height: 1.6;
}

::selection {
  background-color: var(--red);
  color: #ffffff;
}

body {
  position: relative;
  min-height: 100vh;
}

.menu-space {
  padding-bottom: 60px;
}

.page-content {
  padding-bottom: 120px;
}

@media all and (max-width: 1024px) {
  .page-content {
    padding-bottom: 320px;
  }
}

.icon {
  font-size: 2rem;
}

.text-link {
  color: var(--red);
}

a {
  all: unset
}

button:hover,
a:hover {
  cursor: pointer;
}

button {
  border: none;
}

ul {
  list-style-type: none;
}
