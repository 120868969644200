.footer--container {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 2.5rem 0;
  background-color: var(--red);
}

.footer--icon-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 10px;
  background-color: rgb(0, 0, 0);
  margin-right: 0.8rem;
}

.footer--email-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.footer--icons {
  display: flex;
}

.footer--icon {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--red);
  font-size: 1.5rem;
  width: 100%;
  height: 100%;
}

.footer--info {
  font-size: 0.8rem;
}

.icon-btn:hover {
  color: #fff;
}

@media screen and (max-width: 1024px) {
  * p {
    line-height: 2;
  }

  .footer--container {
    flex-direction: column;
    gap: 2.7rem;
  }
}