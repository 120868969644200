.not-found--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transform: translateX(50%);
  text-align: center;
}

@media all and (max-width: 768px) {
  .not-found--container {
    transform: translateX(0);
  }
};

.not-found--img-wrapper {
  width: 300px;
}

.not-found--container img{
  width: 100%;
}