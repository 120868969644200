.home--container {
  position: relative;
}

.home--container main {
  position: relative;
  width: 100%;
  height: 100%;
}

.home--video-wrapper {
  position: relative;
  padding: 56.25% 0 0 0;
  aspect-ratio: 16/9;
}

.home--mobile-reel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.home--info-logo-wrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.home--info-container{
  z-index: 1;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-logo {
  width: 250px;
}

/*  */
.home--mobile-reel-wrapper {
  height: 100vh;
  overflow: hidden;
  width: auto;
}

.home--mobile-reel-wrapper>* {
  height: 100%;
  transform: translateX(-35%);
}
/*  */

.home--intro-instafeed-wrapper {
  max-width: 1200px;
  margin: auto;
}

@media all and (max-width: 450px) {
  .home--intro-instafeed-wrapper {

    /* margin-top: -100px; */
    z-index: 99;
  }
}


@media all and (min-width: 451px) {
  .home--info-logo-wrapper,
  .home--mobile-reel-wrapper {
    display: none;
  }

  .home--container {
    padding-top: 0;
  }
}