.alice-carousel__wrapper {
  width: 90%;
  margin: auto;
}

.clients--wrapper .alice-carousel__prev-btn-wrapper {
  position: absolute;
  top: 20%;
  text-align: left;
}

.clients--wrapper .alice-carousel__next-btn-wrapper {
  position: absolute;
  top: 20%;
  right: 0;
  text-align: right;
}
