.contacts--container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  gap: 1.5rem;
  background-color: var(--red);
  padding: 1rem 2rem;
  height: 65%;
}

.contacts--container * {
  color: #fff;
}

.contacts--title hr {
  border-top: 3px solid #fff;
  margin-top: 0.3rem;
}

.contacts--title h1 {
  font-size: 1.5rem;
}

.contacts--person-info>* {
  padding-bottom: 0.5rem;
}

@media all and (max-width: 768px) {
  .contacts--container {
    margin-top: 3rem;
  }
}